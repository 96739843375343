import React, { useState } from 'react'
import { Link } from 'gatsby'
import { Box, Button, Image } from '@chakra-ui/core'
import { icon } from '../assets/img'

const enLink = 'https://docs.google.com/presentation/d/e/2PACX-1vQ7meQConYKPKRMGGn6sDcMWVMQlA7LEi8EHuhj-CjVh3I6P-sr_7rfHzuLyjM-eRmJKUv_1fnHQMVc/embed?start=false&loop=false&delayms=5000'
const jpLink = 'https://docs.google.com/presentation/d/e/2PACX-1vQY-vzpWN4EoNHuJPgjkZ3TTKyUJ_LV5DJDwBRb0h-RmbghmRfbYmkA_fe6fcWfT00kUD7N13usNO_h/embed?start=false&loop=false&delayms=5000'

export default () => {
  const [lang, setLang] = useState('EN')
  const toggleLang = () => setLang(lang==='EN'?'JP':'EN')

  return (
    <Box style={{
      padding: '1rem',
      display: 'flex',
      justifyContent: 'center',
      height: '100vh',
      alignItems: 'center',
    }}>
      <Link to='/'  style={{position: 'absolute', left: '5px', top: '5px', zIndex: '999'}}>
        <Image
          h={50}
          src={icon}
          alt='Kwa'
        />
      </Link>
      <Button onClick={toggleLang} style={{position: 'absolute', right: '5px', top: '5px', zIndex: '999'}}
        size='lg'
        isRound={true}
        variant='ghost'
      >
        { lang==='EN' ? '🇺🇸' : '🇯🇵' }
      </Button>
      <iframe src={lang==='EN'?enLink:jpLink}
        title={lang+'ppt'}
        frameborder='0'
        width='100%'
        height='960px'
        allowfullscreen='true'
        mozallowfullscreen='true'
        webkitallowfullscreen='true'
      ></iframe>
    </Box>
  )
}
